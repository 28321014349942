import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from 'utils/ThemeConstant';
import { AUTH_TOKEN } from 'utils/AuthConstant';

// export const BASE_URL = 'http://localhost:8000/api/'; //Dev
// export const BASE_URL = 'http://65.2.63.111:8000/api/'; //Demo
export const BASE_URL = 'https://api.taxtrade.in/api/'; //Live
export const APP_NAME = 'TAXTRADE';
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';
export const REDIRECT_URL_KEY = 'redirect'
export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/dashboards/default`;
export const UNAUTHENTICATED_ENTRY = '/login'
export const PAGE_SIZE = 20

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'en',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#3e82f7',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: 'light',
	direction: DIR_LTR,
	blankLayout: false
};

export const user_roles = () => {
    return []
}

export const Authorization = () => {
    return {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN) || null}`
    }
}

export const ImageAuthorization = {
    'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN) || null}`
}

export const getStorageUser = () => {
    const user = localStorage.getItem('user')
    if(user) {
        return JSON.parse(user)
    }
    return {}
}