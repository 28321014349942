import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
    name: 'profile',
    initialState: {
        user: {},
        roles: [],
    },
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload
        },
        setRoles: (state, action) => {
            state.roles = action.payload
        },
        clearUser: (state) => {
            state.user = {};
            state.roles = [];
        },
    },
});

export const { setUser, clearUser, setRoles } = userSlice.actions;

export default userSlice.reducer;
