import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH, getStorageUser } from 'utils/Config'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    
]

var protectedRoutes = [
    {
        key: 'dashboards',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'managers',
        path: `${APP_PREFIX_PATH}/managers`,
        component: React.lazy(() => import('views/app-views/users/managers')),
    },
    {
        key: 'managers.add',
        path: `${APP_PREFIX_PATH}/managers/add`,
        component: React.lazy(() => import('views/app-views/users/managers/add')),
    },
    {
        key: 'managers.edit',
        path: `${APP_PREFIX_PATH}/managers/edit/:id`,
        component: React.lazy(() => import('views/app-views/users/managers/edit')),
    },
    {
        key: 'executives',
        path: `${APP_PREFIX_PATH}/executives`,
        component: React.lazy(() => import('views/app-views/users/executives')),
    },
    {
        key: 'executives.add',
        path: `${APP_PREFIX_PATH}/executives/add`,
        component: React.lazy(() => import('views/app-views/users/executives/add')),
    },
    {
        key: 'executives.edit',
        path: `${APP_PREFIX_PATH}/executives/edit/:id`,
        component: React.lazy(() => import('views/app-views/users/executives/edit')),
    },
    {
        key: 'crms',
        path: `${APP_PREFIX_PATH}/crms`,
        component: React.lazy(() => import('views/app-views/users/crms')),
    },
    {
        key: 'crms.add',
        path: `${APP_PREFIX_PATH}/crms/add`,
        component: React.lazy(() => import('views/app-views/users/crms/add')),
    },
    {
        key: 'crms.edit',
        path: `${APP_PREFIX_PATH}/crms/edit/:id`,
        component: React.lazy(() => import('views/app-views/users/crms/edit')),
    },
    {
        key: 'clients',
        path: `${APP_PREFIX_PATH}/clients`,
        component: React.lazy(() => import('views/app-views/users/clients')),
    },
    {
        key: 'clients.add',
        path: `${APP_PREFIX_PATH}/clients/add`,
        component: React.lazy(() => import('views/app-views/users/clients/add')),
    },
    {
        key: 'clients.edit',
        path: `${APP_PREFIX_PATH}/clients/edit/:id`,
        component: React.lazy(() => import('views/app-views/users/clients/edit')),
    },
    {
        key: 'clients.view-client',
        path: `${APP_PREFIX_PATH}/view-client/:id`,
        component: React.lazy(() => import('views/app-views/users/clients/view-client')),
    },
    {
        key: 'users.permissions',
        path: `${APP_PREFIX_PATH}/:userType/permissions/:id`,
        component: React.lazy(() => import('views/app-views/users/permissions')),
    },
    {
        key: 'tasks',
        path: `${APP_PREFIX_PATH}/tasks`,
        component: React.lazy(() => import('views/app-views/tasks')),
    },
    {
        key: 'tasks.add',
        path: `${APP_PREFIX_PATH}/tasks/add`,
        component: React.lazy(() => import('views/app-views/tasks/add')),
    },
    {
        key: 'tasks.edit',
        path: `${APP_PREFIX_PATH}/tasks/edit/:id`,
        component: React.lazy(() => import('views/app-views/tasks/edit')),
    },
    {
        key: 'tasks.view-task',
        path: `${APP_PREFIX_PATH}/tasks/view-task/:id`,
        component: React.lazy(() => import('views/app-views/tasks/view-task')),
    },
    {
        key: 'recurring_tasks',
        path: `${APP_PREFIX_PATH}/recurring_tasks`,
        component: React.lazy(() => import('views/app-views/recurring_tasks')),
    },
    {
        key: 'recurring_tasks.add',
        path: `${APP_PREFIX_PATH}/recurring_tasks/add`,
        component: React.lazy(() => import('views/app-views/recurring_tasks/add')),
    },
    {
        key: 'recurring_tasks.edit',
        path: `${APP_PREFIX_PATH}/recurring_tasks/edit/:id`,
        component: React.lazy(() => import('views/app-views/recurring_tasks/edit')),
    },
    {
        key: 'invoices',
        path: `${APP_PREFIX_PATH}/invoices`,
        component: React.lazy(() => import('views/app-views/invoices')),
    },
    {
        key: 'invoices.add',
        path: `${APP_PREFIX_PATH}/invoices/add`,
        component: React.lazy(() => import('views/app-views/invoices/add')),
    },
    {
        key: 'invoices.edit',
        path: `${APP_PREFIX_PATH}/invoices/edit/:id`,
        component: React.lazy(() => import('views/app-views/invoices/edit')),
    },
    {
        key: 'invoices.invoice',
        path: `${APP_PREFIX_PATH}/invoices/view-invoice/:id`,
        component: React.lazy(() => import('views/app-views/invoices/view-invoice')),
    },
    {
        key: 'recurring_invoices',
        path: `${APP_PREFIX_PATH}/recurring_invoices`,
        component: React.lazy(() => import('views/app-views/recurring_invoices')),
    },
    {
        key: 'recurring_invoices.add',
        path: `${APP_PREFIX_PATH}/recurring_invoices/add`,
        component: React.lazy(() => import('views/app-views/recurring_invoices/add')),
    },
    {
        key: 'recurring_invoices.edit',
        path: `${APP_PREFIX_PATH}/recurring_invoices/edit/:id`,
        component: React.lazy(() => import('views/app-views/recurring_invoices/edit')),
    },
    {
        key: 'expenses',
        path: `${APP_PREFIX_PATH}/expenses`,
        component: React.lazy(() => import('views/app-views/expenses')),
    },
    {
        key: 'receipts',
        path: `${APP_PREFIX_PATH}/receipts`,
        component: React.lazy(() => import('views/app-views/receipts')),
    },
    {
        key: 'receipts.add',
        path: `${APP_PREFIX_PATH}/receipts/add`,
        component: React.lazy(() => import('views/app-views/receipts/add')),
    },
    {
        key: 'receipts.view-receipts',
        path: `${APP_PREFIX_PATH}/receipts/view-receipts/:receiptId`,
        component: React.lazy(() => import('views/app-views/receipts/view-receipts')),
    },
    {
        key: 'credit_nots',
        path: `${APP_PREFIX_PATH}/credit_nots`,
        component: React.lazy(() => import('views/app-views/credit_nots')),
    },
    {
        key: 'credit_nots.add',
        path: `${APP_PREFIX_PATH}/credit_nots/add`,
        component: React.lazy(() => import('views/app-views/credit_nots/add')),
    },
    {
        key: 'credit_nots.edit',
        path: `${APP_PREFIX_PATH}/credit_nots/edit/:id`,
        component: React.lazy(() => import('views/app-views/credit_nots/edit')),
    },
    {
        key: 'client_ledgers',
        path: `${APP_PREFIX_PATH}/reports/client_ledgers`,
        component: React.lazy(() => import('views/app-views/reports/client_ledger')),
    },
    {
        key: 'user_ledger',
        path: `${APP_PREFIX_PATH}/reports/user_ledger`,
        component: React.lazy(() => import('views/app-views/reports/user_ledger')),
    },
    {
        key: 'followup',
        path: `${APP_PREFIX_PATH}/followup`,
        component: React.lazy(() => import('views/app-views/followup')),
    },
    {
        key: 'client_outstanding_report',
        path: `${APP_PREFIX_PATH}/followup/client_outstanding_report`,
        component: React.lazy(() => import('views/app-views/followup/client_outstanding_report')),
    },
    {
        key: 'followup.history',
        path: `${APP_PREFIX_PATH}/followup/history/:clientId`,
        component: React.lazy(() => import('views/app-views/followup/history')),
    },
    {
        key: 'enquiries',
        path: `${APP_PREFIX_PATH}/enquiries`,
        component: React.lazy(() => import('views/app-views/enquiries')),
    },
    {
        key: 'enquiries.add',
        path: `${APP_PREFIX_PATH}/enquiries/add`,
        component: React.lazy(() => import('views/app-views/enquiries/add')),
    },
    {
        key: 'enquiries.edit',
        path: `${APP_PREFIX_PATH}/enquiries/edit/:id`,
        component: React.lazy(() => import('views/app-views/enquiries/edit')),
    },
    {
        key: 'setting',
        path: `${APP_PREFIX_PATH}/setting`,
        component: React.lazy(() => import('views/app-views/setting')),
    },
    {
        key: 'departments',
        path: `${APP_PREFIX_PATH}/settings/departments`,
        component: React.lazy(() => import('views/app-views/settings/departments')),
    },
    {
        key: 'services',
        path: `${APP_PREFIX_PATH}/settings/services`,
        component: React.lazy(() => import('views/app-views/settings/services')),
    },
    {
        key: 'billing_entity',
        path: `${APP_PREFIX_PATH}/settings/billing_entity`,
        component: React.lazy(() => import('views/app-views/settings/billing_entity')),
    },
    {
        key: 'billing_entity.add',
        path: `${APP_PREFIX_PATH}/settings/billing_entity/add`,
        component: React.lazy(() => import('views/app-views/settings/billing_entity/add')),
    },
    {
        key: 'billing_entity.edit',
        path: `${APP_PREFIX_PATH}/settings/billing_entity/edit/:id`,
        component: React.lazy(() => import('views/app-views/settings/billing_entity/edit')),
    },
    {
        key: 'recurring_task_categories',
        path: `${APP_PREFIX_PATH}/settings/recurring_task_categories`,
        component: React.lazy(() => import('views/app-views/settings/recurring_task_categories')),
    },
    {
        key: 'recurring_task_categories.add',
        path: `${APP_PREFIX_PATH}/settings/recurring_task_categories/add`,
        component: React.lazy(() => import('views/app-views/settings/recurring_task_categories/add')),
    },
    {
        key: 'recurring_task_categories.edit',
        path: `${APP_PREFIX_PATH}/settings/recurring_task_categories/edit/:id`,
        component: React.lazy(() => import('views/app-views/settings/recurring_task_categories/edit')),
    },
    {
        key: 'one_time_task_categories',
        path: `${APP_PREFIX_PATH}/settings/task_categories`,
        component: React.lazy(() => import('views/app-views/settings/task_categories')),
    },
    {
        key: 'packages',
        path: `${APP_PREFIX_PATH}/settings/packages`,
        component: React.lazy(() => import('views/app-views/settings/packages')),
    }
]

const superAdminRoutes = [
   
    {
        key: 'dashboard.super_admin',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/super_admin')),
    },
    {
        key: 'dashboard.admin',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'admins',
        path: `${APP_PREFIX_PATH}/admins`,
        component: React.lazy(() => import('views/app-views/users/admins')),
    },
    {
        key: 'admins.add',
        path: `${APP_PREFIX_PATH}/admins/add`,
        component: React.lazy(() => import('views/app-views/users/admins/add')),
    },
    {
        key: 'admins.edit',
        path: `${APP_PREFIX_PATH}/admins/edit/:id`,
        component: React.lazy(() => import('views/app-views/users/admins/edit')),
    },
    {
        key: 'users.permissions',
        path: `${APP_PREFIX_PATH}/:userType/permissions/:id`,
        component: React.lazy(() => import('views/app-views/users/permissions')),
    },
]

export { protectedRoutes, superAdminRoutes }


export const roles = [
    {
        key: 'dashboards',
        title: 'Dashboards',
        children: [
            // {
            //     userType: 'super_admin',
            //     key: 'dashboard.super_admin',
            //     title: 'Super Admin Dashboard',
            // },
            {
                key: 'dashboard.admin',
                title: 'Admin Dashboard',
            },
        ],
    },
    {
        key: 'enquiries',
        title: 'Enquiries',
        children: [
            {
                key: 'enquiries.add',
                title: 'Enquiry Add',
            },
            {
                key: 'enquiries.edit',
                title: 'Enquiry Edit',
            },
            {
                key: 'enquiries.delete',
                title: 'Enquiry Delete',
            },
            {
                key: 'enquiries.update_status',
                title: 'Enquiry Update Status',
            },
            {
                key: 'enquiries.convert_to_client',
                title: 'Enquiry Convert To Client',
                checked: true
            }
        ],
    },
    {
        key: 'tasks.menu',
        title: 'Tasks',
        children: [
            {
                key: 'tasks',
                title: 'Tasks',
                children: [
                    {
                        key: 'tasks.add',
                        title: 'Create Task',
                    },
                    {
                        key: 'tasks.edit',
                        title: 'Edit Task',
                    },
                    {
                        key: 'tasks.delete',
                        title: 'Delete Task',
                    },
                    {
                        key: 'tasks.view-task',
                        title: 'View Task',
                    },
                    {
                        key: 'tasks.generate-invoice',
                        title: 'Generate Invoice',
                    },
                ]
            },
            {
                key: 'recurring_tasks',
                title: 'Recurring Tasks',
                children: [
                    {
                        key: 'recurring_tasks.add',
                        title: 'Create Recurring Tasks',
                    },
                    {
                        key: 'recurring_tasks.edit',
                        title: 'Edit Recurring Tasks',
                    },
                    {
                        key: 'recurring_tasks.delete',
                        title: 'Delete Recurring Tasks',
                    },
                ]
            },
        ]
    },

    {
        key: 'invoices.menu',
        title: 'Billing & Collections',
        children: [
            {
                key: 'invoices',
                title: 'Invoices',
                children: [
                    {
                        key: 'invoices.add',
                        title: 'Add Invoice',
                    },
                    {
                        key: 'invoices.edit',
                        title: 'Edit Invoice',
                    },
                    {
                        key: 'invoices.delete',
                        title: 'Delete Invoice',
                    },
                    {
                        key: 'invoices.invoice',
                        title: 'View Invoice',
                    },
                ]
            },
            {
                key: 'recurring_invoices',
                title: 'Recurring Invoices',
                children: [
                    {
                        key: 'recurring_invoices.add',
                        title: 'Add Recurring Invoices',
                    },
                    {
                        key: 'recurring_invoices.edit',
                        title: 'Edit Recurring Invoices',
                    },
                    {
                        key: 'recurring_invoices.delete',
                        title: 'Delete Recurring Invoices',
                    },
                ]
            },
            {
                key: 'tasks.pending_invoice',
                title: 'Pending Invoice',
            },
            {
                key: 'receipts',
                title: 'Receipts',
                children: [
                    {
                        key: 'receipts.add',
                        title: 'Add Receipt',
                    },
                    {
                        key: 'receipts.view-receipts',
                        title: 'View Receipt',
                    },
                ]
            },
            {
                key: 'credit_nots',
                title: 'Credit Note',
                children: [
                    {
                        key: 'credit_nots.add',
                        title: 'Add Credit Note',
                    },
                    {
                        key: 'credit_nots.edit',
                        title: 'Edit Credit Note',
                    },
                ]
            },
            {
                key: 'expenses',
                title: 'Expenses',
                children: [
                    {
                        key: 'expenses.add',
                        title: 'Add Expenses',
                    },
                    {
                        key: 'expenses.edit',
                        title: 'Edit Expenses',
                    },
                    {
                        key: 'expenses.delete',
                        title: 'Delete Expenses',
                    },
                ]
            }
        ]
    },
    {
        key: 'reports',
        title: 'Reports',
        children: [
            {
                key: 'client_ledgers',
                title: 'Client Ledgers',
            },
            {
                key: 'user_ledger',
                title: 'User Ledgers',
            },

        ]
    },
    {
        key: 'followup.menu',
        title: 'Follow Up',
        children: [
            {
                key: 'client_outstanding_report',
                title: 'Client Outstanding Report',
            },
            {
                key: 'followup',
                title: 'Follow Up',
            },
            {
                key: 'followup.history',
                title: 'Follow Up History',
            }
        ]
    },
    {
        key: 'users',
        title: 'Users',
        children: [
            {
                key: 'users.permissions',
                title: 'Can Mange Permissions',
            },
            {
                key: 'managers',
                title: 'Managers',
                children: [
                    {
                        key: 'managers.add',
                        title: 'Add Manager',
                    },
                    {
                        key: 'managers.edit',
                        title: 'Edit Manager',
                    },
                    {
                        key: 'managers.delete',
                        title: 'Delete Manager',
                    },
                ]
            },
            {
                key: 'executives',
                title: 'Executives',
                children: [
                    {
                        key: 'executives.add',
                        title: 'Add Executive',
                    },
                    {
                        key: 'executives.edit',
                        title: 'Edit Executive',
                    },
                    {
                        key: 'executives.delete',
                        title: 'Delete Executive',
                    },
                ]
            },
            {
                key: 'crms',
                title: 'Client Relationship Manager',
                children: [
                    {
                        key: 'crms.add',
                        title: 'Add Client Relationship Manager',
                    },
                    {
                        key: 'crms.edit',
                        title: 'Edit Client Relationship Manager',
                    },
                    {
                        key: 'crms.delete',
                        title: 'Delete Client Relationship Manager',
                    },
                ]
            },
            {
                key: 'clients',
                title: 'Clients',
                children: [
                    {
                        key: 'clients.add',
                        title: 'Add Client',
                    },
                    {
                        key: 'clients.edit',
                        title: 'Edit Client',
                    },
                    {
                        key: 'clients.delete',
                        title: 'Edit Delete',
                    },
                    {
                        key: 'clients.view-client',
                        title: 'View Client',
                    },
                    {
                        key: 'clients.deactivate',
                        title: 'Deactivate Client',
                    },
                    {
                        key: 'clients.import',
                        title: 'Import Client',
                    },
                ]
            },

        ]
    },
    {
        key: 'setting',
        title: 'Setting',
    },
    {
        key: 'settings',
        title: 'Settings',
        children: [
            {
                key: 'departments',
                title: 'Departments',
            },
            {
                key: 'services',
                title: 'Services',
            },
            {
                key: 'recurring_task_categories',
                title: 'Recurring Task Categories',
                children: [
                    {
                        key: 'recurring_task_categories.add',
                        title: 'Add Recurring Task Categories',
                    },
                    {
                        key: 'recurring_task_categories.edit',
                        title: 'Edit Recurring Task Categories',
                    },
                    {
                        key: 'recurring_task_categories.delete',
                        title: 'Delete Recurring Task Categories',
                    },
                ]
            },
            {
                key: 'one_time_task_categories',
                title: 'One Time Task Categories',
            },
            {
                key: 'billing_entity',
                title: 'Billing Entity',
                children: [
                    {
                        key: 'billing_entity.add',
                        title: 'Add Billing Entity',
                    },
                    {
                        key: 'billing_entity.edit',
                        title: 'Edit Billing Entity',
                    },
                    {
                        key: 'billing_entity.delete',
                        title: 'Delete Billing Entity',
                    },
                ]
            },
            {
                key: 'packages',
                title: 'Packages',
            },
        ]
    },
]
