import React, { useEffect, useState } from 'react';
import { Routes as RouterRoutes, Route, Navigate } from 'react-router-dom';
import { APP_PREFIX_PATH, AUTHENTICATED_ENTRY } from 'utils/Config';
import { protectedRoutes, publicRoutes, superAdminRoutes } from 'utils/RoutesConfig';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';
import AppRoute from './AppRoute';
import { useSelector } from 'react-redux';

const Routes = () => {

    const { roles, user } = useSelector((state) => state.profile);
    const [protectedRoutes1, setProtectedRoutes1] = useState(protectedRoutes)

    useEffect(() => {
        if (user?.userType) {
            if (user.userType === 'super_admin') {
                setProtectedRoutes1(superAdminRoutes)
            } else {
                const filterRoutes = protectedRoutes1.filter(route => roles.includes(route.key))
                setProtectedRoutes1(filterRoutes)
            }
        }
    }, [user])

    return (
        <RouterRoutes>
            <Route path="/" element={<ProtectedRoute />}>
                <Route path="/" element={<Navigate replace to={AUTHENTICATED_ENTRY} />} />
                {protectedRoutes1.map((route, index) => {
                    return (
                        <Route
                            key={route.key + index}
                            path={route.path}
                            element={
                                <AppRoute
                                    routeKey={route.key}
                                    component={route.component}
                                    {...route.meta}
                                />
                            }
                        />
                    )
                })}
                <Route path="*" element={<Navigate to="/" replace />} />
            </Route>
            <Route path="/" element={<PublicRoute />}>
                {publicRoutes.map(route => {
                    return (
                        <Route
                            key={route.path}
                            path={route.path}
                            element={
                                <AppRoute
                                    routeKey={route.key}
                                    component={route.component}
                                    {...route.meta}
                                />
                            }
                        />
                    )
                })}
            </Route>
        </RouterRoutes>
    )
}

export default Routes